
// Import library functions for theme creation.
@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin app-select-direction-color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat-get-color-config($config-or-theme);
  // Extract the palettes you need from the theme definition.
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);

  .mat-button-toggle {
    background-color:lightgray;
  }

  .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($primary) !important;
    color: mat.get-color-from-palette($primary, 100) !important;
  }
}


@mixin app-select-direction-typography($config-or-theme) {
  // Extract the typography configuration in case a theme has been passed.
  $config: mat-get-typography-config($config-or-theme);

  .candy-carousel {
    font: {
      family: mat.font-family($config, body-1);
      size: mat.font-size($config, body-1);
      weight: mat.font-weight($config, body-1);
    }
  }
}


@mixin app-select-direction-theme($theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat-get-color-config($theme);
  $typography: mat-get-typography-config($theme);

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color != null {
    @include app-select-direction-color($color);
  }
  @if $typography != null {
    @include app-select-direction-typography($typography);
  }
}
