@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin app-operative-primary-color($config-or-theme) {
  $config: mat-get-color-config($config-or-theme);
  $primary: map-get($config, primary);
  $primaryColor:  mat.get-color-from-palette($primary);

}

.section-header-operative {
    display: flex;
    flex-direction: row;
    align-items: center;
    color:'#ffffff';
}

.section-header-operative h3{
  margin: 0 !important;
}
