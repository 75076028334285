.app-loading {
  .logo {
    width: 240px;
    height: 240px;

    // this way asset gets processed by webpack
    background: url(./assets/img/fx_x.svg) center center no-repeat;
    background-size: 240px 240px;

    background-color: white;
    border-radius: 15px;
    padding: 10px;
  }
}

