// Import library functions for theme creation.
@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin app-glulam-color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat-get-color-config($config-or-theme);
  // Extract the palettes you need from the theme definition.
  $primary: map-get($config, primary);
  $accent: map-get($config, primary);

  // Define any styles affected by the theme.
  .section-header {
    // Use mat-color to extract individual colors from a palette.
    background-color: mat.get-color-from-palette($primary, A200);
    border-color: mat.get-color-from-palette($primary);
    color: mat.get-color-from-palette($primary, 500);
  }

  .k-expander-header {
    // Use mat-color to extract individual colors from a palette.
    background-color: mat.get-color-from-palette($primary, A200) !important;
    border-color: mat.get-color-from-palette($primary) !important;
    color: mat.get-color-from-palette($primary, 500) !important;
  }
}

@mixin app-glulam-typography($config-or-theme) {
  // Extract the typography configuration in case a theme has been passed.
  $config: mat-get-typography-config($config-or-theme);

  .section-header h3 {
    font: {
      family: mat.font-family($config, body-1);
      size: mat.font-size($config, body-2);
      weight: mat.font-weight($config, body-1);
    }
  }
}

@mixin app-glulam-theme($theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat-get-color-config($theme);
  $typography: mat-get-typography-config($theme);

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color !=null {
    @include app-glulam-color($color);
  }

  @if $typography !=null {
    @include app-glulam-typography($typography);
  }
}

@mixin app-gluelam-flex-theme {
  .main-content-wrapper {
    overflow: hidden !important;
  }

  .main-content-margin {
    margin-right: 1px !important;
  }
}

.section-header {
  display: flex;
  flex-direction: row;
  padding: 5px;
  align-items: center;
  top: 0;
  z-index: 1;
}

.section-header h3 {
  margin: 0 !important;
}

.selected-glue-plan {
  border: 2px solid orange !important;
  height: fit-content !important;
}

.glueset-actions {
  justify-content: space-between;
}

.m-5 {
  margin: 5px !important;
}

.mb-1 {
  margin-bottom: 10px;
}

.mt-1 {
  margin-top: 10px;
}

.glue-plan-card-ations {
  display: flex;
  align-items: flex-end;
}

.glueset-layers-chart {
  margin-bottom: 15px;
}

.d-flex {
  display: flex;
}

.glueplan-details {
  padding: 0 5px;
}

.w-100 {
  width: 100%;
}

.p-0 {
  padding: 0px !important;
}

.p-15 {
  padding: 15px;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.k-expander-content {
  padding: 0px !important;
}

.fw-bold {
  font-weight: 500 !important;
}

.glueset {
  border: 0.5px solid lightgrey;
  border-radius: 5px;
  padding: 0 5px;
  margin-top: 5px;
}

.glue-plan-mat-card-headder {
  border: 0.5px solid lightgrey;
  border-radius: 5px;
  width: 100%;
}

.app-gluing-plan-layered-view {
  padding: 5px 5px 5px 0;
}

.d-flex-col-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
}
