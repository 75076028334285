@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin aot-grid-selectedCell-theme($theme) {

    $colorConfig: mat-get-color-config($theme);
    @if $colorConfig != null {
        @include aot-grid-selectedcell-color($colorConfig);
    }
}

@mixin aot-grid-selectedcell-color($colorConfig) {

    $accent: map-get($colorConfig, accent);
    .selected-cell {
         background: mat.get-color-from-palette($accent);
        color: mat.get-color-from-palette($accent, 900);
      }
}

.text-right {
  text-align: right;
}
