/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import "@progress/kendo-font-icons/dist/index.css";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

@media print {

  .no-print,
  .no-print * {
    display: none !important;
  }


}

.example-h2 {
  margin: 10px;
}

.example-section {
  display: flex;
  align-content: center;
  align-items: center;
  height: 60px;
}

.example-section mat-form-field {
  margin-right: 10px;
}

.example-margin {
  margin: 0 10px;
}

.input-mini {
  width: 75px;
}

/*style for bml-menu-component*/
.menuContainer {
  background: white;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.2);
}

.cursor-pointer {
  cursor: pointer;
}

.app-button-row {
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  padding-bottom: 5px;
  gap: 10px;
  align-items: flex-end;
}

.pointer {
  cursor: pointer;
}

.k-pager-nav,
.k-pager-nav>.k-button-text {
  color: #fe7021 !important;
}

.k-button-md {
  padding-inline: 5px !important;
  padding-block: 0px !important;
}

.k-button-solid-primary:hover {
  background-color: #FE8D4D !important;
  border-color: #FE8D4D;
}

.k-button-solid-secondary:hover {
  background-color: #FEAF4D !important;
  border-color: #FEAF4D;
}

.k-button-solid-error:hover {
  background-color: #FE6B4D !important;
  border-color: #FE6B4D;
}

.k-grid td,
.k-grid .k-table-td {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.k-link {
  padding-inline: 0px !important;
  padding-inline-start: 10px !important;
}

.k-button-solid-primary,
.k-button-solid-secondary,
.k-button-solid-warning,
.k-button-solid-error,
.k-button-solid-success {
  color: #ffffff !important;
}

.k-button:disabled, .k-button.k-disabled {
  color: #00000061 !important;
}

.k-actions .k-button-solid-base,
.k-form-buttons .k-button-solid-base,
.k-edit-buttons .k-button-solid-base,
.k-action-buttons .k-button-solid-base,
.k-columnmenu-actions .k-button-solid-base {
  background-color: #ffffff !important;
  box-shadow: var(--kendo-elevation-2, 0px 1px 5px 0px rgba(0, 0, 0, .2), 0px 3px 1px 0px rgba(0, 0, 0, .12), 0px 2px 2px 0px rgba(0, 0, 0, .14)) !important;
  color: #000000de;
  border-color: #fff !important;
}

.min-width-dropdown {
  min-width: 230px;
}

.width100Percentage {
  width: 100%;
}

.text-right {
  text-align: center;
  color: brown;
}

.crud-grid {
  width: 100%;
  height: calc(100vh - 200px);
}

.row {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.col {
  display: flex;
  flex-direction: column;
  gap: 5px 10px;
}

.app-dialog-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.app-dialog-content>* {
  margin-bottom: 10px;
}

mat-dialog-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 5px;
}

.mini {
  font: italic 10px sans-serif;
}

.small {
  font: italic 12px sans-serif !important;
}

.heavy {
  font: bold 30px sans-serif;
}

.rotate {
  transform: translate(20px, 0px) rotate(90deg);
  font-weight: bold;
}

.no-pointer {
  pointer-events: none;
}

/* Note that the color of the text is set with the    *
       * fill property, the color property is for HTML only */
.Rrrrr {
  font: italic 40px serif;
}

.white-stroke {
  fill: white !important;
  stroke-width: 1px !important;
}

.mat-drawer.mat-drawer-side {
  z-index: 1 !important;
}


.k-tabstrip-items .k-link {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.k-tabstrip-content,
.k-tabstrip>.k-content {
  padding-block: 15px ! important;
  padding-inline: 15px ! important;
}

.k-window-titlebar {
  padding-block: 10px ! important;
  padding-inline: 10px ! important;
}

.k-appbar.k-appbar-top {
  min-height: 64px !important;
}

.k-drawer-item {
  font-weight: 400;
  padding-block: 8px !important;
  margin: 2px;
}

.k-drawer-item.k-drawer-separator {
  padding-block: 0px !important;
  background-color: #ffffff;
}

.k-drawer-item.k-selected {
  padding-block: 8px !important;
  margin: 2px;
  border-radius: 5px;
  font-weight: 400;
}

.fixed-drawer {
  background-color: white;
  position: fixed;
  top: 64px;
  z-index: 100;
  width: 299px;
}

.unfix-drawer {
  background-color: white;
  position: fixed;
  top: 0px;
  z-index: 100;
  width: 299px;
}

[kendodrawerlist] {
  position: relative;
  top: 18em;
}

[kendo-drawer] {
  width: 300px;
}

.k-drawer-wrapper {
  height: 100%;
}

.k-drawer-content {
  margin: 10px !important;
}
