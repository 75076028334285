//@import "bourbon";

// Breakpoints
$bp-maggie: 15em;
$bp-lisa: 30em;
$bp-bart: 48em;
$bp-marge: 62em;
$bp-homer: 75em;
$prim-color: #3f51b5;

.print-table {

  border-collapse: collapse;
  margin-bottom: 25px;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 225px;

thead {
    // Accessibly hide <thead> on narrow viewports
    tr {
      background-color: $prim-color;
    color: #ffffff;
    text-align: left;
    }
  }

  th,
  td {
    padding: 12px 15px;
  }

  tbody {

    tr {
      border-bottom: 1px solid #dddddd;

      &:last-of-type {
        border-bottom: 2px solid $prim-color;
      }

      &:nth-of-type(even) {
        background-color: #f3f3f3;
      }
    }
  }
}

.print-table-total{
  td{
    font-weight: bold;
  }
}
