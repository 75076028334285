@use '@angular/material' as mat;
@use "sass:map";
@import '@angular/material/theming';
@import 'pallets.scss';
@import '../app/modules/aot/aot-grid-main/aot-grid-main-theme.component.scss';
@import '../app/modules/gluelam/scss/glulam-theme.scss';
@import '../app/modules/app-controls/select-entity-relation-direction/select-entity-relation-direction.component.scss';
@import '../app/modules/operative/scss/operative-theme.scss';

// Theme Material
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$fx-typography: mat.define-legacy-typography-config($font-family: 'Roboto',
    $headline: mat.define-typography-level(16px, 20px, 700),
    $body-1: mat.define-typography-level(14px, 20px, 400),
    $body-2: mat.define-typography-level(14px, 20px, 400));

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$fx-theme: mat.define-light-theme((color: (primary: mat.define-palette($md-optinex-primary), // Remember to change color code in helpers/theme-colors-helper.ts
        accent: mat.define-palette($md-opitenex-secondary, A200, A100, A400), // Remember to change color code in helpers/theme-colors-helper.ts
        accentSecond: mat.define-palette($md-fxgreen, A200, A100, A400),
        warn: mat.define-palette($md-fxwarn),

      ),
      typography: $fx-typography));




// Include theme styles for core and each component used in your app.

@include mat.all-legacy-component-themes($fx-theme);

@include mat.all-component-themes($fx-theme);

@include aot-grid-selectedCell-theme($fx-theme);

@include app-glulam-color($fx-theme);

@include app-select-direction-theme($fx-theme);

@include app-operative-primary-color($fx-theme);

@mixin app-mixin($fx-theme) {
  $config: mat-get-color-config($fx-theme);
  $primary: map-get($config, primary);
  $secondary: map-get($config, accent);
  $primaryColor: mat.get-color-from-palette($primary);
  $secondaryColor: mat.get-color-from-palette($secondary);

  [mat-dialog-title] {
    margin: -24px -24px 0px -24px !important;
    padding: 10px 24px;
    background: $primaryColor;
    color: white;
  }

  [kendo-dialog-title] {
    padding: 10px 24px;
    background: $primaryColor;
    color: white;
    padding-block: 0.5em !important;
  }

  [kendo-dialog-action] {
    height: 50px;
  }
}

@include app-mixin($fx-theme);

// Kendo Theming
$kendo-color-primary: map.get($md-optinex-primary, 500);
$kendo-color-secondary: map.get($md-opitenex-secondary, 400);
$kendo-color-warning: map.get($md-fxwarn, 400);
$warning: map.get($md-fxwarn, 400);
$kendo-enable-typography: false;
$font-size: 13px;
$kendo-drawer-bg: #ffffff;
$kendo-font-size: 5px;
$kendo-grid-cell-padding-x: 1px;
$kendo-grid-cell-padding-y: 1px;
$kendo-table-cell-padding-y: 1px;
$kendo-table-cell-padding-x: 1px;
$kendo-grid-padding-y: 0px;

$kendo-drawer-selected-bg:map.get($md-opitenex-secondary, A200);
$kendo-drawer-selected-color:map.get($md-optinex-primary, 500);

.k-drawer-item.k-selected{
  color: map.get($md-optinex-primary, 500) !important;
  background: map.get($md-opitenex-secondary, A200) !important;
}
$kendo-dialog-titlebar-bg: map.get($md-optinex-primary, 500);
$kendo-dialog-titlebar-text: white;

$kendo-hovered-bg:  map.get($md-optinex-primary, 400);

@import "@progress/kendo-theme-material/dist/all.scss";
