/* For use in src/lib/core/theming/_palette.scss */
$md-fxblue: (
    50 : #e1e5e8,
    100 : #b3bdc5,
    200 : #81929f,
    300 : #4f6679,
    400 : #29455c,
    500 : #03243f,
    600 : #032039,
    700 : #021b31,
    800 : #021629,
    900 : #010d1b,
    A100 : #5991ff,
    A200 : #266fff,
    A400 : #0051f2,
    A700 : #0048d9,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-fxgreen: (
    50 : #f6faed,
    100 : #e8f4d3,
    200 : #d9ecb5,
    300 : #cae497,
    400 : #bedf81,
    500 : #b3d96b,
    600 : #acd563,
    700 : #a3cf58,
    800 : #9aca4e,
    900 : #8bc03c,
    A100 : #ffffff,
    A200 : #f0ffdb,
    A400 : #daffa8,
    A700 : #d0ff8f,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-fxblack: (
    50 : #e0e0e0,
    100 : #b3b3b3,
    200 : #808080,
    300 : #4d4d4d,
    400 : #262626,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #a6a6a6,
    A200 : #8c8c8c,
    A400 : #737373,
    A700 : #666666,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-fxwarn: (
    50 : #fce6ea,
    100 : #f7c1ca,
    200 : #f198a6,
    300 : #eb6e82,
    400 : #e74f68,
    500 : #e3304d,
    600 : #e02b46,
    700 : #dc243d,
    800 : #d81e34,
    900 : #d01325,
    A100 : #ffffff,
    A200 : #ffcdd0,
    A400 : #ff9aa1,
    A700 : #ff808a,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-optinex-primary: (
    50 : #ffeee4,
    100 : #ffd4bc,
    200 : #ffb890,
    300 : #fe9b64,
    400 : #fe8d4d,
    500 : #fe7021,
    600 : #fe681d,
    700 : #fe5d18,
    800 : #fe5314,
    900 : #fd410b,
    A100 : #ffffff,
    A200 : #FFE0CE, //fff6f4, //FFE0CE
    A400 : #ffccc1,
    A700 : #ffb7a7,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-opitenex-secondary: (
    50 : #ffeee4,
    100 : #ffd4bc,
    200 : #ffb890,
    300 : #fe9b64,
    400 : #fe8542,
    500 : #fe7021,
    600 : #fe681d,
    700 : #fe5d18,
    800 : #fe5314,
    900 : #fd410b,
    A100 : #ffffff,
    A200 : #FFE0CE, //fff6f4, //FFE0CE
    A400 : #ffccc1,
    A700 : #ffb7a7,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
        )
    );


